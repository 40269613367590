import { isbot } from "isbot";
/**
 * 閲覧履歴をCookieに保存する（10件まで）
 */
export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return;
  // botの場合は記録しない
  if (isbot(navigator.userAgent)) return;
  $fetch("/api/session/add", {
    body: { path: to.fullPath },
    method: "POST",
  }).catch(() => {
    console.info("Failed to save history. Maybe the session has expired.");
  });
});
