<script setup lang="ts">
const { dataLayer } = useScriptGoogleTagManager();
const config = useLocalSite();
useHead({
  titleTemplate: (title) => {
    return title
      ? `${title} - ファミリー庭園${config.shop_name}`
      : `ファミリー庭園${config.shop_name}`;
  },
});

// GTM ページビューイベントを送信
useScriptEventPage((ctx) => {
  dataLayer.push({
    event: "pageview",
    path: ctx.path,
    title: ctx.title,
  });
});
</script>

<template>
  <NuxtLoadingIndicator color="#01561f" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
