export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return;

  // @nuxt/scripts使用の場合は無効にしたい
  const config = useRuntimeConfig();
  if (config.public.scripts.googleTagManager.id) return;

  // @nuxt/scripts未使用の場合
  sendGtmPageView({
    pagePath: to.path, //遷移先ページのpath
    pageTitle: to.name, //遷移先ページのpath
    pagePathPrev: from.path, //遷移前のページのpath
    pagePathTitle: from.name, //遷移前のページのtitle
  });
});

function sendGtmPageView({
  pagePath,
  pageTitle,
  pagePathPrev,
  pagePathTitle,
}: any) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "Pageview",
    pagePath,
    pageTitle,
    pagePathPrev,
    pagePathTitle,
  });
}
