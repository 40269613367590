import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/opt/buildhome/repo/web/middleware/history.global.ts";
import pageview_45global from "/opt/buildhome/repo/web/middleware/pageview.global.ts";
import redirect_45global from "/opt/buildhome/repo/web/middleware/redirect.global.ts";
export const globalMiddleware = [
  validate,
  history_45global,
  pageview_45global,
  redirect_45global
]
export const namedMiddleware = {}