export default defineNuxtRouteMiddleware((to) => {
  // 施工例attr_num ●ページ目
  // sekou/exterior111_4
  //  => sekou/exterior111?off=4
  let m;
  if ((m = to.path.match(/^\/sekou\/([a-z0-9]+)_([0-9]+)$/))) {
    const path = to.fullPath.split("_");
    const new_path = `${path[0]}?off=${path[1].split("?")[0]}`;
    console.log(`redirect from ${to.path} to ${new_path}`);
    return navigateTo(new_path, { redirectCode: 301 });
  }

  // 旧お客様レビュー 手紙ページ リダイレクト
  // /letter.php/084066
  // =>/letter_e.php/l084066
  if (to.path.match(/^\/letter.php\/([0-9]+)$/)) {
    const path = to.fullPath.replace(/^\/letter.php\//, "/letter_e.php/l");
    console.log(`redirect from ${to.path} to ${path}`);

    return navigateTo(path, { redirectCode: 301 });
  }

  // 旧お客様レビュー 一覧ページ リダイレクト
  // /l_list.php || e_list.php
  // =>/e_list
  if (to.path.match(/^\/l_list.php/) || to.path.match(/^\/e_list.php/)) {
    console.log(`redirect from ${to.path} to /e_list`);
    return navigateTo("/e_list", { redirectCode: 301 });
  }

  // 職人募集フォーム リダイレクト
  // /inquiry/job/form_job.php
  // => /recruit/contractor
  if (to.path.match(/^\/inquiry\/job\/form_job.php\/?$/)) {
    console.log(`redirect from ${to.path} to /recruit/contractor`);
    return navigateTo("/recruit/contractor", { redirectCode: 301 });
  }

  // 新卒採用募集フォーム リダイレクト
  // /newgrad
  // => /recruit/newgrad
  if (to.path.match(/^\/newgrad\/?$/)) {
    console.log(`redirect from ${to.path} to /recruit/newgrad`);
    return navigateTo("/recruit/newgrad", { redirectCode: 301 });
  }

  // 中途採用募集フォーム リダイレクト
  // /career
  // => /recruit/career
  if (to.path.match(/^\/career\/?$/)) {
    console.log(`redirect from ${to.path} to /recruit/career`);
    return navigateTo("/recruit/career", { redirectCode: 301 });
  }

  // 問い合わせフォーム リダイレクト
  // /inquiry/form_2.php
  // => /inquiry/form
  if (to.path.match(/^\/inquiry\/form_2.php\/?$/)) {
    console.log(`redirect from ${to.path} to /inquiry/form`);
    return navigateTo("/inquiry/form", { redirectCode: 301 });
  }

  // 404の場合のリダイレクト
  if (to.matched.length === 0) {
    // 画像ディレクトに存在しない場合、静的サーバー(旧サーバー）から取得する
    if (to.path.match(/^\/images?/)) {
      console.log(to.path);
      return navigateTo("https://static.famitei.co.jp" + to.path, {
        redirectCode: 301,
        external: true,
      });
    }
  }

  // /sp以下へのアクセスは/spを除いたパスにリダイレクト
  if (to.path.match(/^\/sp/)) {
    console.log(
      `redirect from ${to.fullPath} to ${to.fullPath.replace(/^\/sp/, "")}`,
    );
    let path = to.fullPath.replace(/^\/sp/, "");
    if (!path) path = "/";
    return navigateTo({ path, query: to.query });
  }
});
