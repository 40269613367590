import { SITE_DATA } from "../constants/site";

function getSiteData(key: string) {
  const target = SITE_DATA.find((e) => e.shop_key == key);
  if (target) return target;
  return SITE_DATA[0];
}

export default function () {
  const key = useRuntimeConfig().public.siteKey;

  return getSiteData(key);
}
