import revive_payload_client_g65RmjhC48 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dBgOfZ3R4S from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5yKgO47Vsh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Rdo15qm0S5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.9_@types+node@22.7.7_sass@1.80._4yyxm5cawauvthljnvxkdsjgae/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_88NydZ5MkQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3QtFi2JQQR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_rvllEHhqXi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/opt/buildhome/repo/web-locals/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/web-locals/.nuxt/components.plugin.mjs";
import prefetch_client_quaohMhy1t from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jk4n25i3ims2cwis7bgrha3bsq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_FHzvDsxTYH from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.22.4_vite@5.4.9_@types+node@22.7.7_sass@1.80.3_terse_a3exbuwvcl6tfrv4fd2ajcbwhy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import imgwin_client_SiHf1aOUrN from "/opt/buildhome/repo/web/plugins/imgwin.client.ts";
import sentry_client_A9HLV6Ua2r from "/opt/buildhome/repo/web/plugins/sentry.client.ts";
import vue_final_modal_qbCQSD8hof from "/opt/buildhome/repo/web/plugins/vue-final-modal.ts";
import vue_gtm_client_stBjLl0OeM from "/opt/buildhome/repo/web-locals/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_g65RmjhC48,
  unhead_dBgOfZ3R4S,
  router_5yKgO47Vsh,
  _0_siteConfig_Rdo15qm0S5,
  payload_client_88NydZ5MkQ,
  navigation_repaint_client_3QtFi2JQQR,
  chunk_reload_client_rvllEHhqXi,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_quaohMhy1t,
  plugin_FHzvDsxTYH,
  imgwin_client_SiHf1aOUrN,
  sentry_client_A9HLV6Ua2r,
  vue_final_modal_qbCQSD8hof,
  vue_gtm_client_stBjLl0OeM
]