// 環境変数で切り替わる店舗のデータ
type LocalSiteData = {
  // 店舗識別文字列 URL等に(固定ページ 本体 店舗紹介 p_[○○]に合わせる)
  shop_key: string;
  // 店舗名
  shop_name: string;
  // 都道府県
  prefecture: string;
  // 電話番号
  tel: string;
  // 店舗Googleマップ
  google_map: {
    url: string;
    star: number;
    count: number;
  };
  gtm_id: string;
};

export const SITE_DATA: LocalSiteData[] = [
  // 大阪店 default
  {
    shop_key: "kitaosaka",
    shop_name: "大阪店",
    prefecture: "大阪府",
    tel: "0120-664-4448",
    google_map: {
      url: "https://maps.app.goo.gl/jrtvvhoCsQaWtwkZ7",
      star: 4.6,
      count: 196,
    },
    gtm_id: "GTM-T4L2G8P",
  },
  // 南大阪店
  {
    shop_key: "minamiosaka",
    shop_name: "南大阪店",
    prefecture: "大阪府",
    tel: "0120-974-628",
    google_map: {
      url: "https://maps.app.goo.gl/xrjPC2NgUoiNXUbTA",
      star: 4.7,
      count: 169,
    },
    gtm_id: "GTM-T77W7S8",
  },
  // 奈良店
  {
    shop_key: "naraminami",
    shop_name: "奈良店",
    prefecture: "奈良県",
    tel: "0120-617-022",
    google_map: {
      url: "https://maps.app.goo.gl/5UYL2FnuKgrfZ5tJA",
      star: 4.6,
      count: 145,
    },
    gtm_id: "GTM-T5FFF9L",
  },
  // 兵庫店
  {
    shop_key: "hyogo",
    shop_name: "兵庫店",
    prefecture: "兵庫県",
    tel: "0120-644-128",
    google_map: {
      url: "https://maps.app.goo.gl/F9Z1fhMr5hTyqPM1A",
      star: 4.7,
      count: 173,
    },
    gtm_id: "GTM-PZ3GVL7",
  },
  // 京都店
  {
    shop_key: "kyoto",
    shop_name: "京都店",
    prefecture: "京都府",
    tel: "0120-02-4128",
    google_map: {
      url: "https://maps.app.goo.gl/FjrLGeC6m1EbQR6y7",
      star: 4.6,
      count: 126,
    },
    gtm_id: "GTM-WJTX38J",
  },
  // 滋賀店
  {
    shop_key: "shiga",
    shop_name: "滋賀店",
    prefecture: "滋賀県",
    tel: "0120-04-4128",
    google_map: {
      url: "https://maps.app.goo.gl/BR3xWhezTmihGuNj9",
      star: 4.4,
      count: 170,
    },
    gtm_id: "GTM-THF95HC",
  },
  // 和歌山店
  {
    shop_key: "wakayama",
    shop_name: "和歌山店",
    prefecture: "和歌山県",
    tel: "0736-69-5717",
    google_map: {
      url: "https://maps.app.goo.gl/31anC7MrrafGr5nv8",
      star: 4.4,
      count: 125,
    },
    gtm_id: "GTM-MK5WRWM",
  },
];
